import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
// import { createBrowserHistory } from 'history';

// Analytics
import ReactGA from 'react-ga';
import FullStory from 'react-fullstory';

// Firebase
/* eslint-disable-next-line */
import config from './config/config';
import firebase from "firebase/app";
import "firebase/firestore";

import './index.css';
import Homepage from './components/Homepage/Homepage';
import Projects from './components/Projects/Projects';
import Player from './components/Player/Player';

import * as serviceWorker from './serviceWorker';

// const history = createBrowserHistory();
// const location = history.location;
// const search = history.location.search;

ReactGA.initialize('UA-146465070-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export class AppShell extends Component {
  constructor(props) {
    super(props);

    this.db = firebase.firestore();

    this.state = {
      FS_ORG_ID: '4J527',
      projects: [],
      projectID: [],
    };
  }

  loadProjects = async () => {
    const snapshot = await this.db.collection('comments').get();
    var _joined = [];
    var _projectID = [];
    snapshot.docs.map(doc => _joined.push(doc.data()));
    snapshot.docs.map(info => _projectID.push(info._document.proto.name.split('/')[6]));
    this.setState({
      projects: _joined,
      projectID: _projectID,
    }, () => {
    })
  }

  UNSAFE_componentWillMount() {
    this.loadProjects();
  }
  
  render() {
    const { FS_ORG_ID, projects, projectID } = this.state;
    return (
      <div className="AppShell">
        <FullStory org={FS_ORG_ID} />
        <Router>
          <Route path="/" exact component={Homepage} />
          <Route path="/projects" render={(props) => <Projects projectID={projectID} data={projects} /> } />
          <Route path="/player" component={Player} />
        </Router>
        {/* <Player data={} /> */}
      </div>
    );
  }
}

ReactDOM.render(<AppShell />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
