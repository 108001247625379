import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const style = {
  root: {
  },
}

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return (
      <div style={style.root}>
        Hello from homepage
        <Link to="/">Home</Link>
        <Link to="/projects"> My Projects</Link>
      </div>
    )
  }
}
export default Homepage;