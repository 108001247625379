import React, { Component } from 'react';

const style = {
  root: {
    margin: '0 0 5px 0',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  usr: {
    margin: '0 10px 0 0',
    padding: '4px 0',
    width:  '42px',
    height: '36px',
    color: '#fff',
    fontWeight: '600',
    textAlign: 'center',
    background: '#F05D60',
    border: '2px solid #fff',
    borderRadius: '40px'
  },
  timestamp: {
    margin: '',
    color: '#F05D60',
  },
  msg: {
    padding: '6px 10px',
    width: '100%',
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: '600',
    background: '#fff',
    borderRadius: '2px',
  },
  actions: {
    fontSize: '13px',
    color: '#B8B8B8',
    textAlign: 'right',
    fontWeight: '600',
  },
  actionEdit: {
    margin: '0 20px 0 0',
  },
  actionRemove: {

  },
}

class CommentUI extends Component {
  render () {
    const data = this.props.comment.split('_');
    const data_usr = data[0].toUpperCase();
    const data_pos = data[1] + ' ';
    const data_msg = data[2];
    return (
      <div style={style.root}>
        <div style={style.content}>
          <div style={style.usr}>{data_usr}</div>
          <div style={style.msg}>
            <span style={style.timestamp}>{data_pos}</span>
            {data_msg}
          </div>
        </div>
        <div style={style.actions}>
          <span style={style.actionEdit}>Edit</span>
          <span style={style.actionRemove}>Remove</span>
        </div>
      </div>
    )
  }
}
export default CommentUI;