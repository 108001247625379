import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { messageBuilder } from '../../helpers/utils'

// import * as firebase from "firebase/app";
// import "firebase/auth";
import firebase from "firebase/app";
import "firebase/firestore";
// import "firebase/database";

import Layout from '../layout'

// Left side components
import BackArrow from '../../images/icon_back_arrow.svg'
import TrackInfoUI from './Partials/TrackInfoUI/TrackInfoUI'
import AWSSoundPlayer from '../AWSSoundPlayer/AWSSoundPlayer';

// Right side components
import Annotations from './Partials/AnnotationUI/AnnotationsUI'
import InputTimecodeUI from './Partials/InputTimecodeUI/InputTimecodeUI'

const style = {
  vars: {
    leftWidth: '100%',
    rightWidth: '420px',
  },
  root: {
    fontFamily: `'Proxima Nova', sans-serif`,
    display: 'grid',
    gridTemplateColumns: `calc(100% - 420px) 420px`,
    height: '100vh',
  },
  left: {
    position: 'relative',
    padding: '22px 30px',
    height: '100%',
    background: '#fff',
  },
  right: {
    position: 'relative',
    padding: '22px 30px',
    height: '100%',
    background: '#F5F5F5',
  },
  backButton: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    width: '20px',
    height: '20px',
    backgroundImage: `url(${BackArrow})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  AWSSoundPlayer: {
    position: 'absolute',
    padding: '22px 0 0 0',
    bottom: '0',
    left:   '0',
    width: '100%',
  },
  button: {
    root: {
      padding: '6px 20px',
      color: '#fff',
      fontWeight: '600',
      border: 'none',
      borderRadius: '2px',
      cursor: 'pointer',
    },
    enabled: {
      padding: '6px 20px',
      color: '#fff',
      fontWeight: '600',
      border: 'none',
      borderRadius: '2px',
      background: '#f05d5f',
      WebkitBoxShadow: '0px 9px 15px -1px rgba(240,93,96,0.6)',
      MozBoxShadow: '0px 9px 15px -1px rgba(240,93,96,0.6)',
      boxShadow: '0px 9px 15px -1px rgba(240,93,96,0.6)',
      transition: 'all 0.2s ease 0s',
      cursor: 'pointer',
    },
    disabled: {
      padding: '6px 20px',
      color: '#fff',
      fontWeight: '600',
      border: 'none',
      borderRadius: '2px',
      background: '#C8C8C8',
      transition: 'all 0.2s ease 0s',
    },
  },
};

class Player extends Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.projectID = '';
    this.state = {
      currentTime: '0:00',
      trackData: {},
      comment: null,
      commentList: [],
      userInputExists: false
    };
  }

  handleLoadProject = async () => {
    let commentRef = await this.db.collection('comments').doc(this.projectID);
    commentRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.error('No such document!');
        } else {
          let _trackData = doc.data().trackData;
          let _commentData = doc.data().commentContent;
          this.setState({
            trackData: _trackData,
            commentList: _commentData,
          })
        }
      })
      .catch(err => {
        console.error('Err getting document', err);
      })
  }

  getCurrentTime = () => {
    const node = ReactDOM.findDOMNode(this);
    var child;
    var value;

    if (node instanceof HTMLElement) {
      // Scrape current time from <AudioPlayer />
      child = node.querySelector('.audio-player-time');
      value = child.textContent.split('/')[0].trim();
      this.setState({
        currentTime: value,
      })
    }
  }

  handleChange = () => {
    if (this.userInput.value === '') {
      this.setState({userInputExists: false});
    } else {
      this.setState({userInputExists: true});
    }
  }

  handleClearInput = () => {
    this.userInput.value = "";
    this.setState({
      userInputExists: false
    });
  }

  handleAddComment = () => {
    if (this.state.userInputExists) {
      var _message = messageBuilder('AJ', String(this.state.currentTime), String(this.userInput.value));
      var _joined = this.state.commentList.concat(_message)

      this.setState({
        comment: _message,
        commentList: _joined,
      }, () => {
        this.handleClearInput();
        this.db.collection('comments').doc(this.projectID).set({
          commentContent: this.state.commentList,
        }, {
          merge: true
        });
      })
    }
  }

  componentDidMount() {
    const history = createBrowserHistory();
    this.projectID = history.location.search.split('=')[1];
    this.handleLoadProject();
  }

  render () {
    const {
      currentTime,
      trackData,
      commentList,
      userInputExists,
    } = this.state;
    
    return (
      <Layout>

        {/* Left Side */}
        <div style={style.root}>
          <div style={style.left}>
            <Link to={'/projects'}>
              <div style={style.backButton}></div>
            </Link>
            <TrackInfoUI title={trackData.title} author={trackData.author} />
            <div style={style.AWSSoundPlayer} >
              { trackData.src
                ? <AWSSoundPlayer src={trackData.src} />
                : null
              }
            </div>
          </div>
          {/* END Left Side */}

          {/* Right Side */}
          <div style={style.right}>
            <Annotations data={commentList} />
            <div style={{
                position: 'absolute',
                bottom: '31px',
                left: '30px',
                width: 'calc(100% - 60px)',
                display: 'block',
              }}
            >
              <div 
                style={{
                  position: 'relative',
                  margin: '0 0 10px',
                }}
              >
                <input
                  type='text'
                  placeholder='Say something...'
                  onChange={this.handleChange}
                  onFocus={this.getCurrentTime}
                  ref={e => this.userInput = e}
                  style={{
                    width: '100%',
                    padding: '13px 10px 10px 67px',
                    border: 'none',
                    borderRadius: '2px',
                  }}
                />
                  <InputTimecodeUI data={currentTime} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <button
                  onClick={this.handleAddComment}
                  style={(userInputExists) ? style.button.enabled : style.button.disabled}
                >
                  {'Add'}
                </button>
              </div>
            </div>
          </div>
          {/* END Right Side */}

        </div>
      </Layout>
    )
  }
}

export default Player;
