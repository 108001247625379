import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyAE3JIGDzy_QglF9pWvR-KIJidHDmmvr_A",
  authDomain: "productivv-66984.firebaseapp.com",
  databaseURL: "https://productivv-66984.firebaseio.com",
  projectId: "productivv-66984",
  storageBucket: "productivv-66984.appspot.com",
  messagingSenderId: "259655528229",
  appId: "1:259655528229:web:cf6447c538177f9c"
};

firebase.initializeApp(config);

export default firebase;