import React from 'react'
import AnnotationBubbleUI from '../AnnotationBubbleUI/AnnotationBubbleUI'

const style = {
  root: {
    maxHeight: 'calc(100vh - 160px)',
    overflow: 'scroll',
  },
}

const Annotations = (props) => {
  return (
    <div style={style.root}>
      {props.data.map((comment) => (
        <AnnotationBubbleUI key={comment + String(Math.random()).split('.')[1]} comment={comment} />
      ))}
    </div>
  )
}

export default Annotations;