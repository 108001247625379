import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ProjectCardUI.css';

const style = {
  root: {
    width: '364px',
    height: '547px',
    background: '#fff',
    borderRadius: '4px'
  },
  thumbnail: {
    width: '100%',
    height: '355px',
    background: 'grey',
    borderRadius: '4px 4px 0 0'
  },
  trackData: {
    wrapper: {
      position: 'relative',
      margin: '30px auto 0 auto',
      width: '100%',
      maxWidth: '284px',
      height: '140px',
      fontFamily: 'sans-serif',
    },
    title: {
      marginBottom: '2px',
      fontSize: '30px',
      lineHeight: '1.1em',
      color: '#666666',
    },
    author: {
      fontSize: '15px',
      color: '#F05D60',
      fontWeight: '600',
    },
    modifiedDate: {
      fontSize: '15px',
      color: '#A9A9A9',
    },
  }
}

export class projectCardUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tmp: 'xZMKVeBDFLiCQ9k4lchs',
    };
  }

  render () {
    const { data, projectID } = this.props;
    return (
      <div style={style.root} className="projectCardUI">
        <div style={style.thumbnail}></div>
        <div style={style.trackData.wrapper}>
          <div style={style.trackData.title}>{data.title}</div>
          <div>
            <span style={style.trackData.author}>{data.author}</span>
            <span style={style.trackData.modifiedDate}> August 31, 2019</span>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: '0', width: '100%'}}>
            <span>13 Comments</span>
            <Link to={{pathname: '/player', search: `?project=${projectID}`}}>View Project</Link>
          </div>
        </div>
      </div>
    )
  }
}
export default projectCardUI;