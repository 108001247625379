import React, { Component } from "react";
import AudioPlayer from "../../vendor/react-moduler-audio-player/build/index";
import AudioSpectrum from 'react-audio-spectrum';
import { getAudioBuffer, getContext } from '../../helpers/utils';
import './AWSSoundPlayer.css'

let iconStyle = { 
  width: "fit-content"
}
let rearrangedPlayer = [
  {
    className: "tier-top",
    style: { 
      padding: "0",
      marginTop: '-5px',
      zIndex: 0,
    },
    innerComponents: [
      {
        type: "seek"
      },
    ]
  },
  {
    className: "tier-bottom",
    style: { 
      margin: '14px 0 16px 0',
      padding: '0 30px',
    },
    innerComponents: [
      {
        type: "play",
        style: iconStyle
      },
      {
        type: "time",
        style: iconStyle
      },
      {
        type: "volume",
        style: iconStyle
      },
    ]
  }
];

export class AWSSoundPlayer extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      width: 900,
      visualizerWidth: 0,
      visualizerCount: 0,
      context: getContext(),
      audioFiles: [
        {
          src: this.props.src,
          title: this.props.title,
          artist: this.props.author
        },
      ],
    };
  }


  getFile = async (path = this.props.src) => {
    const buffer = await getAudioBuffer(path, this.state.context);
    this.setState({ buffer });
  };

  handleFile = event => {
    const files = event.target.files;
    const file = window.URL.createObjectURL(files[0]);
    this.getFile(file);
  };

  handleClick = (e) => {
    e.preventDefault();
    console.log('clicked');
  }

  updateVisualizer() {
    var _visualizerCount = () => {
      var audioRefExists = this.audioRef.current ? true : false;
      var refClientWidth = this.audioRef.current.clientWidth;
      if (audioRefExists) {
        if (refClientWidth > 700) {
          return 700 - 200;
        } else {
          return refClientWidth - 200;
        }
      }
    }
    this.setState({
      visualizerWidth: this.audioRef.current ? this.audioRef.current.clientWidth - 38 : null,
      visualizerCount: _visualizerCount(),
    })
  }
  
  componentDidMount() {
    this.getFile();
    this.updateVisualizer();
    window.addEventListener("resize", this.updateVisualizer.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateVisualizer.bind(this));
  }

  render () {
    const { visualizerWidth, visualizerCount, audioFiles } = this.state;
    return (
      <div>
        <div
          style={{
            padding: '0 20px',
            height: '298px',
            overflow: 'hidden',
          }}
          ref={this.audioRef}
        >
          <AudioSpectrum
            audioId={'audio-player'}
            capColor={'#F05D60'}
            capHeight={2}
            gap={3}
            height={300}
            id="audio-canvas"
            meterColor={[
              {stop: 0, color: '#fff'},
              {stop: 0.4, color: '#F05D60'},
              {stop: 1, color: '#F05D60'}
            ]}
            meterCount={visualizerCount}
            meterWidth={3}
            width={visualizerWidth}
          />
        </div>
        <AudioPlayer 
          audioFiles={audioFiles}
          rearrange={rearrangedPlayer}
          playerWidth="unset"
          iconSize="1.5rem"
          fontSize="1rem"
          sliderClass="invert-blue-grey"
        />
      </div>
    )
  }
}

export default AWSSoundPlayer;