import React, { Fragment } from 'react';

const style = {
  root: {
    display: 'block',
    position: "absolute",
    top:  '10px',
    left: '10px',
    width:  '50px',
    height: '30px',
    color: '#fff',
    padding: '4px 0',
    fontWeight: 600,
    textAlign: 'center',
    background: 'rgb(240, 93, 96)',
    borderRadius: '4px',
  }
}

const InputTimecodeUI = (props) => {
  return (
    <Fragment>
      <span style={style.root}>{props.data}</span>
    </Fragment>
  )
}

export default InputTimecodeUI;