import ReactDOM from 'react-dom';

/**
 * From url file path download and return Audio Buffer
 * path: path to audio file
 * context: Audio Context
 */
export const getAudioBuffer = async (path, context) => {
  const response = await fetch(path);
  const audioData = await response.arrayBuffer();
  return new Promise((resolve, reject) => {
    context.decodeAudioData(audioData, buffer => {
      return resolve(buffer);
    });
  });
};

/**
 * Get window audio context
 */
export const getContext = () => {
  window.AudioContext =
    window.AudioContext ||
    window.webkitAudioContext ||
    window.mozAudioContext ||
    window.oAudioContext;
  const context = new AudioContext();
  return context;
};

/**
 *  Message Bulder
 *  usrInitials: <string> Current user initials
 *  timecode: <string> Track timecode
 *  message: <string> Message or annotation
 */
export function messageBuilder(usrInitials, timecode, message) {
  // TODO:
  // - Sanitize user inpit
  return usrInitials + '_' + timecode + '_' + message;
}

/**
 * Returns current timecode from audio player.
 * element: elements identifyer (e.g id or classname)
 */
export function getCurrentTime(element) {
  const node = ReactDOM.findDOMNode(this);
  var child;
  var value;

  if (node instanceof HTMLElement) {
      child = node.querySelector(element);
      value = child.textContent.split('/')[0];
      this.setState({
        currentTime: value,
      })
  }
}