import React from 'react'

const style = {
  root: {
    textAlign: 'center',
    marginTop: '33vh',
    color: '#2d2d2d',
  },
  trackData: {
    title: {
      marginBottom: '-5px',
      fontFamily: '"Proxima Nova", sans-serif',
      fontSize: '80px',
    },
    author: {
      fontFamily: '"Proxima Nova", sans-serif',
      fontSize: '34px',
      fontWeight: '400',
    },
  }
};

const TrackInfoUI = (props) => {
  return (
    <div style={style.root}>
      <h1 style={style.trackData.title}>{props.title}</h1>
      <h5 style={style.trackData.author}>{props.author}</h5>
    </div>
  )
}

export default TrackInfoUI;