import React, { Component } from 'react';
import ProjectCardUI from './Partials/ProjectCardUI';

const style = {
  root: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '1440px',
  },
  project: {
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
}

export class Projects extends Component {
  render() {
    const { projectID, data } = this.props;
    return (
      <div style={style.root}>
        <h1>Productivv</h1>
        <div style={style.project.wrapper}>
        {data.map((cardData, index) =>
          <ProjectCardUI
            key={cardData.trackData.title}
            projectID={projectID[index]}
            data={cardData.trackData}
          />
        )}
        </div>
      </div>
    );
  }
}

export default Projects;